/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}

/* Custom from here */

html {
  background-color: #000000;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  background-color: #000000;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.dynamic {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  overflow: hidden;
}

#footer {
  margin: auto;
  border-top: 1px solid #666666;
  background-color: #000;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
  width: 100%;
}

#footer #room-name {
  padding: 1em;
  color: #eeeeee;
  font-family: monospace;
  flex-shrink: 2;
  text-overflow: ellipsis;
}

#footer #last-update {
  padding: 1em;
  color: #eeeeee;
  font-family: monospace;
}

#warning {
  background-color: #666;
  padding: 1em;
  display: inline-block;
  color: #eeeeee;
  font-family: monospace;
}

.unmuted #footer {
  background-color: red;
}

#video-row {
  background-color: yellow;
  flex-grow: 2;
  margin: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 0;
}

#offline {
  flex-grow: 4;
  margin: auto;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

#offline p {
  font-size: 2em;
  padding-bottom: 1em;
  text-align: center;
}

#offline h1 {
  font-size: 10em;
  text-align: center;
}

#offline p {
  color: #eeeeee;
  font-family: monospace;
}

a {
  color: #ffffff;
}

.start-audio-hopper,
.pause-audio-hopper,
.next-audio-hopper,
.prev-audio-hopper {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: #111111;
  border: 1px solid #444444;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  padding: 10px;
}

.next-audio-hopper {
  right: 60px;
}

.prev-audio-hopper {
  right: 120px;
}

.start-audio-hopper:active,
.pause-audio-hopper:active,
.next-audio-hopper:active,
.prev-audio-hopper:active {
  background-color: #333333;
}

.video-container {
  flex-grow: 1;
  min-height: 0;
}

video {
  background-color: black;
  width: 100%;
  height: 100%;
  min-height: 0;
}

#vu-meter {
  display: none;
  font-family: monospace;
  border-left: 1px solid #666666;
}

.show-vu-meter #vu-meter {
  display: block;
  width: 80px;
  flex-shrink: 0;

  background-color: blue;
}

#nav {
  background-color: #444444;
  padding: 1em;
  display: inline-block;
  color: #eeeeee;
  font-family: monospace;
  border-radius: 0.5em;
}

#nav h1 {
  font-weight: bold;
  font-size: 1.5em;
}

#nav li {
  padding-top: 0.5em;
}

#welcome {
  padding: 20px;
}

#welcome p {
  padding-top: 6vw;
  font-size: 12vw;
}