html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

html, body {
  background-color: #000;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.dynamic {
  flex-direction: column;
  justify-content: space-around;
  display: flex;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

#footer {
  background-color: #000;
  border-top: 1px solid #666;
  flex-flow: row;
  justify-content: space-between;
  align-self: flex-end;
  align-items: center;
  width: 100%;
  margin: auto;
  display: flex;
}

#footer #room-name {
  color: #eee;
  text-overflow: ellipsis;
  flex-shrink: 2;
  padding: 1em;
  font-family: monospace;
}

#footer #last-update {
  color: #eee;
  padding: 1em;
  font-family: monospace;
}

#warning {
  color: #eee;
  background-color: #666;
  padding: 1em;
  font-family: monospace;
  display: inline-block;
}

.unmuted #footer {
  background-color: red;
}

#video-row {
  background-color: #ff0;
  flex-direction: row;
  flex-grow: 2;
  width: 100%;
  min-height: 0;
  margin: auto;
  display: flex;
}

#offline {
  flex-direction: column;
  flex-grow: 4;
  place-content: center;
  margin: auto;
  display: flex;
}

#offline p {
  text-align: center;
  padding-bottom: 1em;
  font-size: 2em;
}

#offline h1 {
  text-align: center;
  font-size: 10em;
}

#offline p {
  color: #eee;
  font-family: monospace;
}

a {
  color: #fff;
}

.start-audio-hopper, .pause-audio-hopper, .next-audio-hopper, .prev-audio-hopper {
  color: #fff;
  cursor: pointer;
  background-color: #111;
  border: 1px solid #444;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.next-audio-hopper {
  right: 60px;
}

.prev-audio-hopper {
  right: 120px;
}

.start-audio-hopper:active, .pause-audio-hopper:active, .next-audio-hopper:active, .prev-audio-hopper:active {
  background-color: #333;
}

.video-container {
  flex-grow: 1;
  min-height: 0;
}

video {
  background-color: #000;
  width: 100%;
  height: 100%;
  min-height: 0;
}

#vu-meter {
  border-left: 1px solid #666;
  font-family: monospace;
  display: none;
}

.show-vu-meter #vu-meter {
  background-color: #00f;
  flex-shrink: 0;
  width: 80px;
  display: block;
}

#nav {
  color: #eee;
  background-color: #444;
  border-radius: .5em;
  padding: 1em;
  font-family: monospace;
  display: inline-block;
}

#nav h1 {
  font-size: 1.5em;
  font-weight: bold;
}

#nav li {
  padding-top: .5em;
}

#welcome {
  padding: 20px;
}

#welcome p {
  padding-top: 6vw;
  font-size: 12vw;
}
/*# sourceMappingURL=all.61fbe1ee.css.map */
